<template>
  <div class="vg_wrapper" v-loading="loadingFlag">
    <el-card>
      <div class="vd_button_group">
        <span v-if="disabled === true">
          <el-button type="success" @click="openEdit" size="small" :disabled="!btn.edit">编辑</el-button>
        </span>
        <span v-if="disabled === false">
          <el-button type="primary" @click="submit('stffForm')" size="mini">保存</el-button>
          <el-button @click="closeEdit" size="mini">取消</el-button>
        </span>
      </div>
      <el-form ref="stffForm" :model="stffForm" :rules="rules" :disabled="disabled" label-width="120px" size="mini">
        <el-row :gutter="24">
          <el-col :md="8">
            <el-form-item label="员工编号" prop="stff_no" required>
              <el-input v-model="stffForm.stff_no" maxlength="10" show-word-limit placeholder="请填写员工编号"></el-input>
            </el-form-item>
            <el-form-item label="员工姓名" prop="stff_name" required>
              <el-input v-model="stffForm.stff_name" maxlength="10" show-word-limit placeholder="请填写员工姓名"></el-input>
            </el-form-item>
            <el-form-item label="身份证号">
              <el-input v-model="stffForm.stff_identity" maxlength="18" show-word-limit placeholder="请填写身份证号"></el-input>
            </el-form-item>
            <el-form-item label="员工性别">
              <el-input v-model="stffForm.stff_gender" placeholder="根据身份信息自动填充" disabled> </el-input>
            </el-form-item>
            <el-form-item label="员工电话">
              <el-input v-model="stffForm.stff_phone" maxlength="30" placeholder="请填写电话"></el-input>
            </el-form-item>
          </el-col>
          <el-col :md="8">
            <el-form-item label="入职时间" prop="stff_enter_time" required>
              <el-date-picker
                type="date"
                placeholder="请选择入职时间"
                v-model="stffForm.stff_enter_time"
                @change="deleteEnterTime"
              ></el-date-picker>
            </el-form-item>
            <el-form-item label="离职时间" prop="stff_leave_time">
              <el-date-picker
                type="date"
                :picker-options="pickerOptions"
                placeholder="请选择离职时间"
                v-model="stffForm.stff_leave_time"
              ></el-date-picker>
            </el-form-item>
            <el-form-item label="员工生日">
              <el-input v-model="stffForm.stff_birthday" placeholder="根据身份信息自动填充" disabled></el-input>
            </el-form-item>
            <el-form-item label="员工年龄" prop="stff_age">
              <el-input v-model="stffForm.stff_age" placeholder="根据身份信息自动填充" disabled></el-input>
            </el-form-item>
          </el-col>
          <el-col :md="8">
            <el-form-item label="公司抬头" prop="cptt_id" required>
              <el-select v-model="stffForm.cptt_id" placeholder="请选择公司抬头" clearable @change="selectCpttName">
                <el-option v-for="item in cpttGroupOpt" :key="item.cptt_id" :label="item.cptt_name" :value="item.cptt_id"> </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="部门名称" prop="dept_id" required>
              <el-select v-model="stffForm.dept_id" placeholder="请选择部门" clearable @change="openTeam">
                <el-option v-for="item in deptGroupOpt" :key="item.dept_id" :label="item.dept_name" :value="item.dept_id"> </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="小组名称" prop="dept_team_id">
              <el-select
                v-model="stffForm.dept_team_id"
                placeholder="请选择小组"
                clearable
                @visible-change="getTeamByDept($event)"
                :disabled="teamDisabled"
              >
                <el-option v-for="item in teamGroupOpt" :key="item.dept_team_id" :label="item.dept_team_name" :value="item.dept_team_id">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="员工岗位">
              <el-select
                filterable
                allow-create
                v-model="stffForm.stff_job"
                maxlength="10"
                placeholder="请选择岗位"
                clearable
                @visible-change="getStffJob($event)"
              >
                <el-option v-for="item in stffJobGroupOpt" :key="item.id" :label="item.param1" :value="item.param1"> </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="上级领导">
              <select-input
                :selTableData="selTableData"
                @refreshClick="refreshClick"
                :tableHeader="staffTableHeader"
                @getSelTableRow="getSelTableRow"
                :inputVal="stffForm.stff_leader_name"
                :disabled="disabled"
                :isShowCol="false"
                :rowSelect="rowSelect"
                placeholder="请选择上级领导"
              >
              </select-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </el-card>
  </div>
</template>

<script>
import { get, post } from '@api/request';
import { stffAPI } from '@api/modules/staff';
import { cpttAPI } from '@api/modules/comptitle';
import { deptAPI } from '@api/modules/department';
import { staffTableHeader } from '@/views/component/tableHeader/staffTableHeader';
import selectInput from '@/views/component/selectInput';
import options from '@/views/component/common/options';
import { dataAPI } from '@api/modules/data';
import { imgeAPI } from '@api/modules/imge';
import helper from '@assets/js/helper';
import { optnAPI } from '@api/modules/optn';
export default {
  name: 'StffAdd.vue',
  components: {
    selectInput
  },
  data() {
    return {
      stffForm: {
        stff_id: null,
        stff_ext_id: null,
        stff_no: '',
        stff_name: '',
        stff_leader: '',
        stff_leader_name: '',
        cptt_id: null,
        dept_id: null,
        dept_team_id: null,
        stff_enter_time: null,
        stff_leave_time: null,
        stff_gender: '',
        stff_birthday: '',
        stff_phone: '',
        stff_identity: '',
        stff_job: ''
      },
      key: 0,
      compTitle: '',
      btn: {},
      disabled: true,
      teamDisabled: true,
      merryDisabled: false,
      rowSelect: [],
      cpttGroupOpt: [],
      deptGroupOpt: [],
      teamGroupOpt: [],
      stffJobGroupOpt: [], // 员工岗位
      selectionsList: [],
      selTableData: [],
      staffTableHeader: [],
      rules: {},
      pickerOptions: this.beginDate(),
      loadingFlag: true
    };
  },
  watch: {
    'stffForm.dept_id': function (newVal, oldVal) {
      if (oldVal) {
        if (newVal !== oldVal) {
          this.stffForm.dept_team_id = null;
          this.teamGroupOpt = [];
        }
      }
    },

    stffForm: {
      deep: true,
      handler(val) {
        if (val.stff_identity) {
          this.stffForm.stff_age = this.helper.mathUserInfo(val.stff_identity, 3);
          this.stffForm.stff_birthday = this.helper.mathUserInfo(val.stff_identity, 1);
          this.stffForm.stff_gender = this.helper.mathUserInfo(val.stff_identity, 2);
        }
      }
    },

    'stffForm.dept_team_id': function (newVal) {
      if (newVal === 0) {
        this.stffForm.dept_team_id = null;
      }
    }
  },
  created() {
    this.initData();
  },
  methods: {
    // 离职时间控制关闭选项
    beginDate() {
      let that = this;
      return {
        disabledDate(time) {
          return time.getTime() < that.stffForm.stff_enter_time;
        }
      };
    },
    refreshClick() {
      this.getSelectTable();
    },
    initData() {
      if (this.$route.query.form_id) {
        this.loadingFlag = true;
        this.getStffInfo();
        this.getCpttV1();
        this.getDeptV1();
        this.getSelectTable();
        this.staffTableHeader = staffTableHeader;
      }
    },
    // 获取传递selTableData
    getSelectTable() {
      get(stffAPI.getAllStffsV1)
        .then(res => {
          if (res.data.code === 0) {
            this.selTableData = res.data.data;
          }
        })
        .catch(res => {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
        });
    },
    // 获取双击selTableData的row
    getSelTableRow(val) {
      this.stffForm.stff_leader = val.stff_id;
      this.stffForm.stff_leader_name = val.stff_name;
    },
    // 获取员工岗位
    getStffJob(flag) {
      if (flag === true && this.stffJobGroupOpt.length === 0) {
        get(optnAPI.getOptnByPermId, { perm_id: 10001 })
          .then(res => {
            if (res.data.code === 0) {
              this.stffJobGroupOpt = res.data.data.form.optn_cntt_list;
            }
          })
          .catch(res => {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
          });
      }
    },

    //启用编辑
    openEdit() {
      this.disabled = false;
      this.teamDisabled = false;
    },
    //取消编辑
    closeEdit() {
      this.$confirm('是否撤销编辑?', '提示', {
        confirmButtonText: '是',
        cancelButtonText: '否',
        type: 'warning'
      })
        .then(() => {
          this.disabled = true;
          this.teamDisabled = true;
          this.initData();
          this.$message({
            type: 'info',
            message: '已撤销编辑!'
          });
        })
        .catch(() => {});
    },

    //提交表单
    submit(formName) {
      this.$confirm('确定提交？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.$refs[formName].validate(valid => {
            if (valid) {
              this.saveInfo();
            } else {
              console.log('error submit!');
              return false;
            }
          });
        })
        .catch(() => {});
    },
    // 保存
    saveInfo() {
      this.stffForm.stff_id = Number(this.$route.query.form_id);
      const stffFormBody = this.stffForm;
      stffFormBody.stff_enter_time = Number(new Date(this.stffForm.stff_enter_time).getTime() / 1000);
      stffFormBody.stff_leave_time = Number(new Date(this.stffForm.stff_leave_time).getTime() / 1000);
      post(stffAPI.editStff, stffFormBody)
        .then(res => {
          if (res.data.code === 0) {
            this.$message({
              type: 'success',
              message: '保存成功'
            });
            this.disabled = true;
            this.teamDisabled = true;
            this.initData();
          } else {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
          }
        })
        .catch(res => {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
        });
    },

    // 获取cpttId
    getCpttV1() {
      get(cpttAPI.getAllCpttsV1)
        .then(res => {
          if (res.data.code === 0) {
            this.cpttGroupOpt = res.data.data;
          }
        })
        .catch(res => {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
        });
    },
    // 获取deptId
    getDeptV1() {
      get(deptAPI.getAllDeptsV1)
        .then(res => {
          if (res.data.code === 0) {
            this.deptGroupOpt = res.data.data;
          }
        })
        .catch(res => {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
        });
    },
    // 获取deptTeamId
    getTeamByDept() {
      if (this.teamGroupOpt.length === 0) {
        get(deptAPI.getTeamsByDeptId, { dept_id: this.stffForm.dept_id })
          .then(res => {
            if (res.data.code === 0) {
              this.teamGroupOpt = res.data.data;
            }
          })
          .catch(res => {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
          });
      }
    },
    // 下拉获取公司抬头的name
    selectCpttName(params) {
      let cpttObj = this.cpttGroupOpt.find(item => {
        return item.cptt_id === params;
      });

      if (cpttObj) {
        this.compTitle = cpttObj.cptt_name;
      }
    },
    //开启选择小组
    openTeam(val) {
      if (val) {
        this.stffForm.dept_id = val;
        this.teamDisabled = false;
      } else {
        this.teamDisabled = true;
      }
    },
    // 清除离职时间
    deleteEnterTime() {
      if (this.stffForm.stff_enter_time > this.stffForm.stff_leave_time) {
        this.stffForm.stff_leave_time = null;
      }
    },
    //获取表单信息
    getStffInfo() {
      this.stffForm.stff_id = this.$route.query.form_id;
      get(stffAPI.getStffById, { stff_id: this.stffForm.stff_id })
        .then(res => {
          if (res.data.code === 0) {
            this.stffForm = res.data.data.form;
            this.compTitle = this.stffForm.cptt_name;
            this.btn = res.data.data.btn;
            if (this.stffForm.stff_enter_time !== 0) {
              this.stffForm.stff_enter_time = Number(this.stffForm.stff_enter_time) * 1000;
            } else {
              this.stffForm.stff_enter_time = null;
            }
            if (this.stffForm.stff_leave_time !== 0) {
              this.stffForm.stff_leave_time = Number(this.stffForm.stff_leave_time) * 1000;
            } else {
              this.stffForm.stff_leave_time = null;
            }
            this.getTeamByDept();
            setTimeout(() => {
              this.loadingFlag = false;
            }, 500);
          } else {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
          }
        })
        .catch(res => {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
        });
    }
  }
};
</script>

<style scoped lang="scss">
.vd_button {
  margin-top: 32px;
  border-top: 1px dashed $color-secondary-text;
  padding-top: 16px;
}
.vd_button_group {
  padding-bottom: 16px;
  margin-bottom: 32px;
  border-bottom: 1px solid $color-secondary-text;
}
.vd_title {
  color: $color-deep-red;
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader ::v-deep .el-upload--text {
  width: 136px;
  height: 136px;
}
.avatar-uploader .el-upload:hover {
  border-color: $color-primary;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: $color-uploader-icon;
  width: 136px;
  height: 136px;
  line-height: 136px;
  text-align: center;
}
.avatar {
  width: 136px;
  height: 136px;
  display: block;
}
</style>
